import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./index.module.scss";
import BackgroundSvg320 from "../../../images/im_bg_cta.png";
import BackgroundSvg768 from "../../../images/im_bg_cta_768.png";
import BackgroundSvg from "../../../images/im_bg_cta_1024.png";
import arrow from "../../../../static/arrow.svg";

import BackgroundImage from "../../../components/BackgroundImage";
import SectionLayout from "../../../components/SectionLayout";

const GetStartedSection = () => {
  const domain =
    process.env.GATSBY_FRONT_DOMAIN || "https://app.stage.fearn.co";

  // const interimLink = process.env.FEARN_WAITLIST_LINK;
  const interimLink = "https://waitlist.stage.fearn.co";

  return (
    <SectionLayout className={styles.getStartedSectionWrapper}>
      <BackgroundImage
        backgroundImageDesktop={BackgroundSvg}
        backgroundImageTablet={BackgroundSvg768}
        backgroundImageMobile={BackgroundSvg320}
        imageStyle={styles.backgroundImageStarted}
      >
        <div className={styles.contentWrapper}>
          <span className={styles.title}>Sell, Earn, Grow</span>
          <div className={styles.buttonWrapper}>
            <Link to={interimLink}>
              <button className={styles.button} type="button">
                Join the Waitlist
              </button>
            </Link>
            <img className={styles.arrowForButton} src={arrow} alt="Arrow" />
          </div>
        </div>
      </BackgroundImage>
    </SectionLayout>
  );
};

export default GetStartedSection;
