import * as React from "react";
import { Link } from "gatsby";

import * as styles from "./index.module.scss";
import arrowImage from "../../../../../../static/arrow_1.svg";

import ROUTES_OUT from "../../../../../constants/routesOut";

const TryItButton = () => {
  const domain =
    process.env.GATSBY_FRONT_DOMAIN || "https://app.stage.fearn.co";

  // const interimLink = process.env.FEARN_WAITLIST_LINK;
  const interimLink = "https://waitlist.stage.fearn.co";

  return (
    <div className={styles.wrapper}>
      <div className={styles.tryItButtonWrapper}>
        <Link to={interimLink}>
          <button className={styles.tryItButton} type="button">
            Join the Waitlist
          </button>
        </Link>
        <img className={styles.arrowImage} src={arrowImage} alt="Arrow" />
      </div>
      <span className={styles.tryItButtonNotice}>
        2 weeks free trial. No credit card required.
      </span>
    </div>
  );
};

export default TryItButton;
